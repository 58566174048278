import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const md = data => {
  const { frontmatter, html } = data.data.allMarkdownRemark.edges[0].node;
  // console.log(frontmatter.title);
  return (
    <div>
      <h1>{frontmatter.title}</h1>
      <p>{frontmatter.date}</p>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

const IndexPage = ({ data, location }) => <Layout location={location}>{md({ data })}</Layout>;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/content/pages/home2/" } }) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
          }
          html
        }
      }
    }
  }
`;

export default IndexPage;
